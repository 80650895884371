// src/pages/Admin.jsx
import { useState } from 'react';
import { Menu, Upload, Button, message, Table } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { getAllFilesFunction, uploadFileFunction } from '@/requests/dataService';
import moment from 'moment';

const Admin = () => {
  const [selectedType, setSelectedType] = useState('cost');
  const [fileList, setFileList] = useState([]);

  const handleMenuClick = (e) => {
    setSelectedType(e.key);
    fetchFileList(e.key);
  };

  const fetchFileList = (type) => {
    // Fetch the list of uploaded files for the selected type from the backend
    getAllFilesFunction(type)
      .then(response => {
        setFileList(response);
      })
      .catch(error => {
        console.error('There was an error fetching the file list!', error);
      });
  };

  const handleUpload = async (info) => {
    if (info.file.status === 'uploading') {
      return;
    }
    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`);
      fetchFileList(selectedType);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const customUpload = async ({ file }) => {
    uploadFileFunction(file, selectedType)
      .then(() => {
        console.log('File uploaded successfully');
        handleUpload({ file: { status: 'done', name: file.name } });
      })
      .catch((error) => {
        console.error('File upload failed:', error);
        handleUpload({ file: { status: 'error', name: file.name } });
      });
  };

  const uploadProps = {
    customRequest: customUpload,
    onChange: handleUpload,
  };

  const columns = [
    {
      title: 'File Name',
      dataIndex: 'file_name',
      key: 'file_name',
    },
    {
      title: 'Upload Date',
      dataIndex: 'created',
      key: 'created',
      render: (text) => moment(text).format('DD-MM-YYYY'),
    },
    {
      title: 'Status',
      dataIndex: 'get_status_display',
      key: 'get_status_display',
    },
  ];

  return (
    <div style={{ padding: '20px' }}>
      <h1>Admin Panel</h1>
      <Menu onClick={handleMenuClick} selectedKeys={[selectedType]} mode="horizontal">
        <Menu.Item key="cost">Cost Files</Menu.Item>
        <Menu.Item key="estimated-sales">Estimated Sales Files</Menu.Item>
        <Menu.Item key="stock">Stock Files</Menu.Item>
        <Menu.Item key="last-year-sales">Last Year Sales Files</Menu.Item>
      </Menu>
      <div style={{ marginTop: '20px' }}>
        <Upload {...uploadProps}>
          <Button icon={<UploadOutlined />}>Upload {selectedType.replace('-', ' ').toUpperCase()} File</Button>
        </Upload>
        <Table dataSource={fileList} columns={columns} rowKey="id" style={{ marginTop: '20px' }} />
      </div>
    </div>
  );
};

export default Admin;
