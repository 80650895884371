import { useEffect, useState } from 'react';
import { Table, Card, Typography, Collapse, Input } from 'antd';
import { fetchStockDataPerOffice } from '@/requests/dataService';

const { Title } = Typography;
const { Panel } = Collapse;
const { Search } = Input;

const StockPerOffice = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await fetchStockDataPerOffice();
        setData(response || {}); // Ensure we set data to an empty object if the response is null
        setLoading(false);
      } catch (error) {
        console.error('There was an error fetching the data!', error);
        setLoading(false);
      }
    };

    getData();
  }, []);

  const handleSearch = (value) => {
    setSearchText(value.toLowerCase());
  };

  const renderTables = (products) => {
    if (!products) return null; // Return null if products are not available

    const filteredData = products.filter((product) =>
      product.sku.toLowerCase().includes(searchText)
    );

    const columns = [
      {
        title: 'Product SKU',
        dataIndex: 'sku',
        key: 'sku',
        sorter: (a, b) => a.sku.localeCompare(b.sku),
      },
      {
        title: 'Expected Stock Quantity',
        dataIndex: 'expected_stock_quantity',
        key: 'expected_stock_quantity',
        sorter: (a, b) => a.expected_stock_quantity - b.expected_stock_quantity,
      },
      {
        title: 'Current Stock Quantity',
        dataIndex: 'current_stock_quantity',
        key: 'current_stock_quantity',
        sorter: (a, b) => a.current_stock_quantity - b.current_stock_quantity,
        render: (_, record) => {
          const quantity = record.current_stock_quantity;
          const backgroundColor = quantity === 0 ? 'rgba(255, 69, 58, 0.7)' : '';
          const color = quantity === 0 ? '#fff' : '#000';
          return (
            <span style={{ backgroundColor, color, padding: '4px 8px', borderRadius: '4px' }}>
              {quantity}
            </span>
          );
        },
      },
      {
        title: 'Difference',
        key: 'difference',
        defaultSortOrder: 'ascend',
        render: (_, record) => {
          const difference = record.current_stock_quantity - record.expected_stock_quantity;
          let backgroundColor = '';
          let color = '#000'; // Default text color
    
          if (record.current_stock_quantity === 0) {
            backgroundColor = 'rgba(255, 69, 58, 0.7)'; // Red if current stock is zero
            color = '#fff'; // White text for contrast
          } else if (difference > 2) {
            backgroundColor = 'rgba(255, 165, 0, 0.7)'; // Orange if difference is greater than two
          } else if (difference < 0) {
            backgroundColor = difference < -2 ? 'rgba(255, 69, 58, 0.7)' : 'rgba(255, 214, 10, 0.7)';
          } else {
            backgroundColor = 'rgba(52, 199, 89, 0.7)'; // Green if no issues
            color = '#fff'; // White text for better readability on green
          }
    
          return (
            <span style={{ backgroundColor, color, padding: '4px 8px', borderRadius: '4px' }}>
              {difference}
            </span>
          );
        },
        sorter: (a, b) => {
          const diffA = a.current_stock_quantity - a.expected_stock_quantity;
          const diffB = b.current_stock_quantity - b.expected_stock_quantity;
    
          // Define the priority
          const priorityA = a.current_stock_quantity === 0 ? 1 : (diffA > 2 ? 2 : (diffA < 0 ? 3 : 4));
          const priorityB = b.current_stock_quantity === 0 ? 1 : (diffB > 2 ? 2 : (diffB < 0 ? 3 : 4));
    
          // Sort by priority first, then by SKU as a secondary sort criteria
          if (priorityA !== priorityB) {
            return priorityA - priorityB;
          } else {
            return a.sku.localeCompare(b.sku);
          }
        },
      },
    ];

    return (
      <Table
        columns={columns}
        dataSource={filteredData}
        pagination={false}
        rowKey="sku"
      />
    );
  };

  return (
    <div style={{ paddingX: '24px' }}>
      <Title level={2}>Stock Per Office</Title>
      <Search
        placeholder="Search by SKU"
        onSearch={handleSearch}
        onChange={(e) => handleSearch(e.target.value)}
        style={{ marginBottom: '16px' }}
        disabled={loading}
      />
      {loading ? (
        <p>Loading...</p>
      ) : (
        <Collapse accordion>
          {Object.keys(data).length > 0 ? (
            Object.keys(data).map((officeName) => (
              <Panel header={officeName} key={officeName}>
                <Card bordered={false}>
                  {renderTables(data[officeName])}
                </Card>
              </Panel>
            ))
          ) : (
            <p>No data available</p>
          )}
        </Collapse>
      )}
    </div>
  );
};

export default StockPerOffice;
